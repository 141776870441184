<template>
    <article class="eventContainer">
        <div>
            <div class="eventTab"></div>
        </div>
        <div>
            <p><b>{{ date }}</b></p>
            <p>
                {{ event }}
                <br />
                <em>{{ place }}</em>
            </p>
        </div>
    </article>
</template>
  
<script>
export default {
    name: 'EventCalendar',
    props: {
        date: String,
        event: String,
        place: String
    },
}
</script>
  
<style lang="scss" scoped>
.eventContainer {
    display: flex;
    margin: 40px 0;
    @media screen and (min-width: 601px) {
        padding-left: 60px;
    }
    div:first-child {
        display: flex;
        align-items: center;
    }
    @media screen and (min-width: 601px) {
        p {
            padding: 8px 20px;
        } 
    }
    @media screen and (max-width: 600px) {
        p {
            font-size: 16px;
            padding: 4px 20px;
            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
    }

}
.eventTab {
    width: 40px;
    height: 72px;
    border-radius: 20px;
    background: $orange;
    @media screen and (min-width: 601px) {
        width: 40px;
        height: 72px;
        border-radius: 20px;
    }
    @media screen and (max-width: 600px) {
        width: 26px;
        height: 48px;
        border-radius: 13px;
    }
}
</style>
