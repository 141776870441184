<template>
    <footer class="container">
        <div class="containerCenterMultiple">
            <article>
                <p class="">Navigation</p>
                <ul>
                    <li>
                        <RouterLink to="/">Accueil</RouterLink>
                    </li>
                    <li>
                        <RouterLink to="/cours">Cours</RouterLink>
                    </li>
                    <li>
                        <RouterLink to="/stages">Stages</RouterLink>
                    </li>
                    <li>
                        <RouterLink to="/tarifs">Tarifs</RouterLink>
                    </li>
                </ul>
            </article>
            <article>
                <p class="">À propos</p>
                <ul>
                    <li>
                        <RouterLink to="/magiciens">Magiciens</RouterLink>
                    </li>
                    <li>
                        <RouterLink to="/galerie">Galerie</RouterLink>
                    </li>
                    <li>
                        <RouterLink to="/agenda">Agenda</RouterLink>
                    </li>
                </ul>
            </article>
            <article>
                <p class="">Contact</p>
                <ul>
                    <li>
                        <a>06 78 09 84 11</a>
                    </li>
                    <li>
                        <a>06 76 83 21 39</a>
                    </li>
                    <li>
                        <a>afrim.kaca@gmail.com</a>
                    </li>
                    <li>
                        <a>3 RUE DU FRÊNE, 06560 Valbonne</a>
                    </li>
                </ul>
            </article>
        </div>
        <div class="copyright">
            <p>© 2024 Association Magie sous les Pins - École de magie à Valbonne - Tous droits réservés.</p>
        </div>
    </footer>
</template>
  
<script>
export default {
    name: 'MainFooter',
}
</script>
  
<style lang="scss" scoped>
article {
    @media screen and (min-width: 601px) {
        padding: 20px 60px;
    }
    @media screen and (max-width: 600px) {
        width: 100%;
        padding: 40px 0;
        text-align: center;
    }
}
a {
    color: $secondary;
    margin: 0;
}
p {
    @media screen and (min-width: 601px) {
        font-size: 28px;
    }
    @media screen and (max-width: 600px) {
        font-size: 16px;
    }
}
li, a {
    &:hover {
        color: $orange;
    }
    @media screen and (min-width: 601px) {
        font-size: 16px;
        padding: 16px 0;
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
        padding: 12px 0;
    }
}

.containerCenterMultiple {
    align-items: flex-start;
}

.container {
    position: relative;
    width: 100%;
    background: $primary;
    color: $secondary;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 20%;
        height: 120px;
        margin-left: 40%;
        border-bottom: 1px solid $secondary;
    }
    @media screen and (min-width: 601px) {
        padding-top: 240px;
    }
    @media screen and (max-width: 600px) {
        padding-top: 120px;
        &:before {
            height: 60px;
        }
    }
}
.copyright {
    padding: 40px 8vw;
    p {
        @media screen and (min-width: 601px) {
            font-size: 16px;
            padding: 16px 0;
        }
        @media screen and (max-width: 600px) {
            font-size: 12px;
            padding: 12px 0;
        }
    }
}
</style>
