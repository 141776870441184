<template>
    <button class="button" :style="style">{{ value }}</button>
</template>
  
<script>
export default {
    name: 'MainButton',
    props: {
        value: String,
        color: String
    },
    computed: {
        colorMap() {
            return {
                $secondary: '#f9f3ec',
                $blue: '#37538c',
                $orange: '#bf612a'
            };
        },
        style() {
            const colorValue = this.colorMap[this.color] || this.color;
            return {
                border: `2px solid ${colorValue}`
            }
        }
    },
}
</script>
  
<style lang="scss" scoped>
.button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px;
    border-radius: 32px;
    background: transparent;
    color:$white;
    @media screen and (min-width: 601px) {
        width: 120px;
        height: 32px;
        padding: 32px 120px;
    }
    @media screen and (max-width: 600px) {
        width: 90px;
        height: 24px;
        padding: 24px 90px;
    }
}
</style>
