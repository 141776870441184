<template>
        <TitleLayer title="Niveaux" image="fanLayer" class="overlay" />
        <section class="mainContainer hidden">
            <div>
                <p class="textPadding">La formation évolue au fur et à mesure que l'élève prend en expérience. Lorsque l'on présente un tour, il est indispensable d'avoir tout un panel d'outils. Bien que la technique soit nécessaire pour rendre un tour possible, ce n'est pas elle qui va le rendre vivant. La magie est un art, elle se doit de générer de l'émotion chez le spectateur. La formation en technique s'accompagne d'un tas d'autres petits éléments, qui sauront différencier nos élèves de la plupart des magiciens. La technicité devient de plus en plus pointue, mais laisse de plus en plus de place à toutes ces notions de présentation.</p>
                <p class="contentCenter"><b>Choisissez une carte !</b></p>
            </div>
            <div class="handedCards">
                <CardFace id="card_1" title="Novice" text="" letter="J" color="$red" symbol="Diamond" class="clearMargin card cursor" v-on:click="selectCard(); getValues($event)" />
                <CardFace id="card_2" title="Intermédiaire" text="" letter="Q" color="$primary" symbol="Club" class="clearMargin card cursor" v-on:click="selectCard(); getValues($event)" />
                <CardFace id="card_3" title="Avancé" text="" letter="K" color="$red" symbol="Heart" class="clearMargin card cursor" v-on:click="selectCard(); getValues($event)" />
                <CardFace id="card_4" title="Copperfield" text="" letter="A" color="$primary" symbol="Spade" class="clearMargin card cursor" v-on:click="selectCard(); getValues($event)" />
            </div>
        </section>
        <div class="selectedCardContainer overlay" :class="isSelected ? 'selected' : 'unselected'" v-on:click="unselectCard">
            <CardFace id="selectedCard_1" title="Novice" text="Initiation, présentation, tenue" letter="J" color="$red" symbol="Diamond" class="clearMargin card selectedCard"  @click.stop="" />
            <CardFace id="selectedCard_2" title="Intermédiaire" text="Formation, technique, communication" letter="Q" color="$primary" symbol="Club" class="clearMargin card selectedCard"  @click.stop="" />
            <CardFace id="selectedCard_3" title="Avancé" text="Perfectionnement, gestuelle, vocabulaire" letter="K" color="$red" symbol="Heart" class="clearMargin card selectedCard"  @click.stop="" />
            <CardFace id="selectedCard_4" title="Copperfield" text="Expertise, maîtrise, contrôle" letter="A" color="$primary" symbol="Spade" class="clearMargin card selectedCard"  @click.stop="" />
        </div>
</template>
  
<script>
import TitleLayer from '../components/TitleLayer.vue'
import CardFace from '../components/CardFace.vue'

export default {
    components: { TitleLayer, CardFace },
    data() {
        return {
            isSelected: false,
        };
    },
    methods: {
        selectCard() {
            this.isSelected = true
        },
        unselectCard() {
            this.isSelected = false
        },
        getValues(e) {
            this.myTitle = 'OK'
            const myCards = document.querySelectorAll('[id^="card_"]')
            
            const firstCard = '#' + myCards[0].id
            const secondCard = '#' + myCards[1].id
            const thirdCard = '#' + myCards[2].id
            const fourthCard = '#' + myCards[3].id
            
            const mySelectedCards = document.querySelectorAll('[id^="selectedCard_"]')

            const myCard = e.target.closest(firstCard) || e.target.closest(secondCard) || e.target.closest(thirdCard) || e.target.closest(fourthCard)
            const mySelectedCardId = 'selectedCard_' + myCard.id.slice(-1)
            const mySelectedCard = document.getElementById(mySelectedCardId)

            mySelectedCards.forEach((card) => {
                card.classList.add('unselected')
            })
            mySelectedCard.classList.remove('unselected')
        }
    },
}
</script>
  
<style lang="scss" scoped>
.overlay {
    background-position: top;
    filter: grayscale(20%);
    &:after {
        background:rgba(23,23,23,.7);
    }
}
.mainContainer {
    @media screen and (min-width: 601px) {
        padding-top: 100px;
        padding-bottom: 200px;
    }
    @media screen and (max-width: 600px) {
        padding-top: 64px;
        padding-bottom: 0;
    }
}
.handedCards {
    position: relative;
    @media screen and (min-width: 601px) {
        height: 700px;
        padding: 120px 20px;
    }
    @media screen and (max-width: 600px) {
        height: 500px;
        padding: 64px 20px;
    }
    @media screen and (max-width: 500px) {
        transform: scale(.8);
    }
}
.card {
    position: absolute;
    // left: calc(50% - 116px - 64px);
    transform-origin: bottom;
    border: 1px solid $primary;
    @media screen and (min-width: 601px) {
        left: calc(50% - 116px - 64px);
    }
    @media screen and (max-width: 600px) {
        left: calc(50% - 58px - 32px);
    }
}
#card_1 {
    z-index: 4;
    @media screen and (min-width: 601px) {
        transform: rotate(-45deg) translateX(-135px) translateY(-45px);
        &:hover {
            transform: rotate(-45deg) translateX(-135px) translateY(-90px);
        }
    }
    @media screen and (max-width: 600px) {
        transform: rotate(-45deg) translateX(-75px) translateY(-45px);
        &:hover {
            transform: rotate(-45deg) translateX(-75px) translateY(-90px);
        }
    }
}
#card_2 {
    z-index: 3;
    @media screen and (min-width: 601px) {
        transform: rotate(-15deg) translateX(-45px) translateY(-45px);
        &:hover {
            transform: rotate(-15deg) translateX(-45px) translateY(-90px);
        }
    }
    @media screen and (max-width: 600px) {
        transform: rotate(-15deg) translateX(-25px) translateY(-45px);
        &:hover {
            transform: rotate(-15deg) translateX(-25px) translateY(-90px);
        }
    }
}
#card_3 {
    z-index: 2;
    @media screen and (min-width: 601px) {
        transform: rotate(15deg) translateX(45px) translateY(-45px);
        &:hover {
            transform: rotate(15deg) translateX(45px) translateY(-90px);
        }
    }
    @media screen and (max-width: 600px) {
        transform: rotate(15deg) translateX(25px) translateY(-45px);
        &:hover {
            transform: rotate(15deg) translateX(25px) translateY(-90px);
        }
    }
}
#card_4 {
    z-index: 1;
    @media screen and (min-width: 601px) {
        transform: rotate(45deg) translateX(135px) translateY(-45px);
        &:hover {
            transform: rotate(45deg) translateX(135px) translateY(-90px);
        }
    }
    @media screen and (max-width: 600px) {
        transform: rotate(45deg) translateX(75px) translateY(-45px);
        &:hover {
            transform: rotate(45deg) translateX(75px) translateY(-90px);
        }
    }
}
.selectedCardContainer {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 5;
}
.selectedCard {
    top: calc(50vh - 250px);
    z-index: 10;
}
.unselected {
    display: none;
}
</style>
