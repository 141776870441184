<template>
    <!-- Top bar for mobile only -->
    <!-- <div class="hide-d topBarWrapper">
        <div id="topBar" class="hide-d">
            <div>
                <h1>Magie sous les pins</h1>
            </div>
            <div v-on:click="showMenu">
                <i v-if="showMobileMenu"><CloseIcon class="icon" /></i>
                <i v-else><MenuIcon class="icon" /></i>
            </div>
        </div>
    </div> -->
    <div v-on:click="showMenu" class="divIcons hide-d">
        <i v-if="showMobileMenu"><CloseIcon fill="#f9f3ec" class="icon" /></i>
        <i v-else><MenuIcon fill="#f9f3ec" class="icon" /></i>
    </div>


    <div class="navWrapper">
        <nav role="navigation" class="menu transition delaySlow" :class="showMobileMenu ? 'show' : 'hide'" data-inviewport="scroll-fade">
            <ul>
                <li>
                    <RouterLink to="/" v-on:click="closeMenu">Accueil</RouterLink>
                </li>
                <li>
                    <a href="#">École
                    </a>
                    <ul class="submenu">
                        <li>
                            <RightArrowIcon fill="#f9f3ec" class="hide-d subIcon" />
                            <RouterLink to="/magiciens" v-on:click="closeMenu">Magiciens</RouterLink>
                        </li>
                        <li>
                            <RightArrowIcon fill="#f9f3ec" class="hide-d subIcon" />
                            <RouterLink to="/galerie" v-on:click="closeMenu">Galerie</RouterLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#">Formations
                    </a>
                    <ul class="submenu">
                        <li>
                            <RightArrowIcon fill="#f9f3ec" class="hide-d subIcon" />
                            <RouterLink to="/cours" v-on:click="closeMenu">Cours</RouterLink>
                        </li>
                        <li>
                            <RightArrowIcon fill="#f9f3ec" class="hide-d subIcon" />
                            <RouterLink to="/niveaux" v-on:click="closeMenu">Niveaux</RouterLink>
                        </li>
                        <li>
                            <RightArrowIcon fill="#f9f3ec" class="hide-d subIcon" />
                            <RouterLink to="/tarifs" v-on:click="closeMenu">Tarifs</RouterLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <!-- <a href="#"><RouterLink to="/InternshipsView">Stages</RouterLink></a> -->
                    <RouterLink to="/stages" v-on:click="closeMenu">Stages</RouterLink>
                </li>
                <li>
                    <RouterLink to="/agenda" v-on:click="closeMenu">Agenda</RouterLink>
                </li>
                <li>
                    <RouterLink to="/contact" v-on:click="closeMenu">Contact</RouterLink>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import MenuIcon from '../assets/icons/MenuIcon.vue';
import CloseIcon from '../assets/icons/CloseIcon.vue';
import RightArrowIcon from '../assets/icons/RightArrowIcon.vue';

export default {
    components: { MenuIcon, CloseIcon, RightArrowIcon },
    data() {
        return {
            showMobileMenu: false,
        };
    },
    methods: {
        showMenu() {
            this.showMobileMenu = !this.showMobileMenu
        },
        closeMenu() {
            this.showMobileMenu = false
        },
    },
    watch: {
        showMobileMenu: function() {
            if (this.showMobileMenu) {
                document.documentElement.style.overflow = 'hidden'
                return
            }
            document.documentElement.style.overflow = 'auto'
        }
    }
}
</script>

<style lang="scss" scoped>
.navWrapper {
    width: 100%;
    position: absolute;
    // @media screen and (min-width: 601px) {
    //     position: absolute;
    // }
    // @media screen and (max-width: 600px) {
    //     position: fixed;
    // }
}

.menu {
    position: relative;
    background: transparent;
    ul {
        display: flex;
    }
    li {
        width: 100%;
        position: relative;

        &:hover {
            .submenu li {
                display: block;
                flex-direction: column;
                top: 0;
            }
        }
    }
    a {
        height: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--main-color-black);
        font-size: 2rem;
        font-weight: bold;
        transition: background 0.4s;
    }
    > ul {
        width: 100%;
        > li {
            @media screen and (min-width: 601px) {
                &:after {
                    content: "";
                    position: absolute;
                    top: calc(50% + 1.5rem);
                    left: calc(50% - 30px);
                    width: 60px;
                    height: 2px;
                    background: $orange;
                    transform: scaleX(0);
                }
                &:hover {
                    &:after {
                        transform: scaleX(1);
                        transition: all .4s ease-in-out 0s;
                        -webkit-transition: all .4s ease-in-out 0s;
                    }
                }
            }
            > a {
                color: $white;
            }
            > ul {
                > li {
                    color: $primary;
                    background: $secondary;

                    &:hover {
                        color: $orange;
                        transition: .4s;
                    }
                }
            }
        }
    }
}

.submenu {
	position: absolute;
	width: 100%;
	flex-direction: column;
	> li {
		display: none;
		position: absolute;
		top: 0;
	}
	li {
		position: relative;
		z-index: 8;
	}
}

/* Mobile menu */
@media screen and (max-width: 600px) {
    .topBarWrapper {
        // position: absolute; // Works with top bar
        height: 40px;
        width: 100%;
    }
    #topBar {
        position: fixed;
        display: flex;
        z-index: 10;
        width: 100%;
        height: 40px;
        div:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100% - 40px);
            height: 40px;
        }
        div:last-child {
            width: 40px;
            height: 40px;
        }
    }
    .divIcons {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 12;
    }
    .menu {
        width: 100%;
        // height: calc(100% - 4rem); // Works with top bar
        height: 100%;
        position: fixed;
        z-index: 10;
        a {
            justify-content: start;
            padding-left: 1.6rem;
        }
        ul {
            flex-direction: column;
        }
        > ul {
            background: $primary;
            overflow: scroll;
            > li {
                position: relative;
                // border-bottom: 1px solid $secondary;
            }
        }
        .subIcon {
            position: absolute;
            height: 20px;
            transform: translateY(30px);
            left: 1rem;
        }
    }
    .submenu {
        position: static;
        a {
            background: $primary; 
            color: $secondary; 
            font-size: 1.2rem;
            padding-left: 3.2rem;
            // &:hover {
            //     &:before {
            //         background-image: url(../medias/icons/arrow-right--light.svg);
            //     }
            // }
        }
    }
    li {
        display: block !important; // Quick fix KEST
    }
}

/* Responsive menu */
@media screen and (max-width: 600px) {
    .menu {
        display: none;
    }
    .show {
        display: flex;
    }
    .hide {
        display: none;
    }
}
@media screen and (min-width: 601px) {
    .menu {
        display: block !important;
    }
}
</style>
