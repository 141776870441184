<template>
    <!-- <div class="title">
        <h2>Magiciens</h2>
    </div> -->
    <TitleLayer title="Magiciens" image="backCardsLayer" class="overlay" />

    <section class="clearPaddingTop clearPadding-h">
        <div class="sliderComponent">
            <MagicianRecord image="afrim_kaca.jpg" name="Afrim KAÇA" info="Spécialiste des cartes et avec de forts attraits pour le mentalisme, l'hypnose et tout autre accessoire du type balle en mousse ou élastique, Afrim décide d'ouvrir sa propre école de magie pour transmettre ses 40 ans d'expérience en magie. L'aboutissement de l'histoire d'une vie." bg_color="#032c3e" bg_image="fan-back" class="transition" />
            <MagicianRecord image="flo_1.png" name="Florayan" info="Le coeur sur la main, investi, créatif et pédagogue, Florayan met un point d'honneur à susciter de l'émotion. Spécialiste des cartes, il fait vivre la magie à travers tout ce qu'il touche. Il ne transmet pas sa magie, il la réinvente pour chaque élève ou spectateur." bg_color="#5e1213"  bg_image="multiple-cut" class="transition invisible" />
            <MagicianRecord image="bebel.png" name="Belkheïr DJENANE" info="Bébel, parrain de l'école, est un cartomane réputé. Le roi du close-up comme on l'appelle a Paris, voyage dans toute l'Europe pour faire briller son talent. D'une fluidité sans égal, il fait le tour des théâtres et des chaînes de télévision, en s'arrêtant de temps en temps à Valbonne pour soutenir l'école." bg_color="#bf612a"  bg_image="3-aces" class="transition invisible" />
            <MagicianRecord image="jean-jacques_sanvert.png" name="Jean-Jacques SANVERT" info="Jean-Jacques, parrain de l'école, a obtenu le prix de champion du monde des cartes en magie. Il s'est ensuite spécialisé en triche, puis en mentalisme. Alors qu'il continue ses allers et venues aux États-Unis, il ne manque pas de passer par l'école pour partager son expérience." bg_color="#032c3e"  bg_image="fan-face" class="transition invisible" />
        </div>
        <div class="sliderSelector hide-m">
            <div class="selectArrows">
                <button v-on:click="decrement(); highlight()"><LeftArrow /></button>
                <button v-on:click="increment(); highlight()"><RightArrow /></button>
            </div>
            <div class="selectDots">
                <div class="transition selectedDot"></div>
                <div class="transition"></div>
                <div class="transition"></div>
                <div class="transition"></div>
            </div>
        </div>
    </section>
</template>

<script>
import TitleLayer from '../components/TitleLayer.vue'
import MagicianRecord from '../components/MagicianRecord.vue'
import LeftArrow from '../assets/icons/LeftArrowIcon.vue'
import RightArrow from '../assets/icons/RightArrowIcon.vue'

export default {
    components: { TitleLayer, MagicianRecord, LeftArrow, RightArrow },
    data() {
        return {
            position: 0,
        };
    },
    methods: {
        decrement() {
            this.position === 0 ? this.position = 3 : this.position--;
        },
        increment() {
            this.position === 3 ? this.position = 0 : this.position++;
        },
        highlight() {
            const dots = document.querySelector('.selectDots').childNodes
            const layouts = document.querySelector('.sliderComponent').childNodes
            console.log(layouts)

            dots.forEach((dot) => {
                dot.classList.remove('selectedDot')
            })
            dots[this.position].classList.add('selectedDot')

            layouts.forEach((layout) => {
                // layout.classList.remove('z1')
                layout.classList.add('invisible')
            })
            // layouts[this.position].classList.add('z1')
            layouts[this.position].classList.remove('invisible')
            layouts[this.position].classList.add('visible')
        }
    },
}
</script>

<style lang="scss" scoped>
.title {
    text-align: center;
    margin-bottom: 16px;
    background: $primary;
    color: $secondary;
    @media screen and (min-width: 601px) {
        padding-top: 8rem;
    }
}

.sliderComponent {
    @media screen and (min-width: 601px) {
        position: relative;
        display: flex;
        align-items: center;
        height: 600px;
        // background: green;
    }
    @media screen and (max-width: 600px) {
        display: grid;
        position: relative;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        margin-left: 1rem;
        padding: 1rem;
        grid-auto-flow: column;
        grid-auto-columns: calc(100vw - 2rem);
        grid-template-columns: repeat(auto-fill,calc(100vw - 2rem));
        gap: 1rem;
        scroll-snap-type: x mandatory;
    }
}

@media screen and (min-width: 601px) {
    .sliderSelector {
        display: flex;
        flex-direction: column;
        margin: 4rem 0;
    }
    .selectArrows {
        display: flex;
        justify-content: center;
        button {
            width: 6rem;
            height: 6rem;
            margin: 2rem;
            padding: 0;
            border-radius: 4rem;
            cursor: pointer;
            &:hover {
                background: #fff;
            }
        }
    }
    .selectDots {
        display: flex;
        justify-content: center;
        div {
            width: .8rem;
            height: .8rem;
            margin: 2rem;
            border-radius: 1rem;
            background: $secondary;
        }
    }
    .selectedDot {
        transform: scale(2);
    }
}
</style>
