<template>
    <div class="title">
        <h2>Galerie</h2>
    </div>
    <div class="parent">
        <div class="div1 overlay" v-on:mouseover="hideTitle($event)" v-on:mouseout="showTitle($event)">
            <p>Fête de la courge</p>
            <img src="../assets/images/gallery/image-stand.png" />
        </div>
        <div class="div2 overlay" v-on:mouseover="hideTitle($event)" v-on:mouseout="showTitle($event)">
            <p>Magie de Noël</p>
            <img src="../assets/images/gallery/image-noel.png" />
        </div>
        <div class="div3 overlay" v-on:mouseover="hideTitle($event)" v-on:mouseout="showTitle($event)">
            <p>Jardin en fête</p>
            <img src="../assets/images/gallery/image-stand-3.png" />
        </div>
        <div class="div4 overlay" v-on:mouseover="hideTitle($event)" v-on:mouseout="showTitle($event)">
            <p>Soirée close up</p>
            <img src="../assets/images/gallery/image-expo.png" />
        </div>
        <div class="div5 overlay" v-on:mouseover="hideTitle($event)" v-on:mouseout="showTitle($event)">
            <p>Sortilèges d'Halloween</p>
            <img src="../assets/images/gallery/image-halloween.png" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isVisible: true,
        };
    },
    methods: {
        showTitle(event) {
            const pElement = event.currentTarget.querySelector('p')
            pElement.classList.remove('hide')
        },
        hideTitle(event) {
            const pElement = event.currentTarget.querySelector('p')
            pElement.classList.add('hide')
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    text-align: center;
    margin-bottom: 16px;
    background: $primary;
    color: $secondary;
    @media screen and (min-width: 601px) {
        padding-top: 8rem;
    }
}
p {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    font-weight: bold;
    // text-shadow: -1px -1px 1px #fff, -1px 1px 1px #fff, 1px -1px 1px #fff, 1px 1px 1px #fff;
}
.overlay {
    &:after {
        background:rgba(255,255,255,.6);
    }
    &:hover {
        &:after {
            background: none;
        }
    }
}
.hide {
    display: none !important;
}

@media screen and (min-width: 601px) {
    .parent {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        padding: 0 20px 20px 20px;
    }
    .div1, .div2, .div3, .div4, .div5 {
        position: relative;
        padding: 0;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            padding: 0;
        }
    }
    .div1 {
        grid-area: 1 / 1 / 2 / 7;
        background: purple;
    }
    .div2 {
        grid-area: 1 / 7 / 2 / 13;
        background: purple;
    }
    .div3 {
        grid-area: 2 / 1 / 3 / 5;
        background: green;
    }
    .div4 {
        grid-area: 2 / 5 / 3 / 9;
        background: green;
    }
    .div5 {
        grid-area: 2 / 9 / 3 / 13;
        background: green;
    }
}

@media screen and (max-width: 600px) {
    img {
        width: 100%;
        padding: 8px 0;
    }
}
</style>
