<template>
    <div class="container" :style="style">
        <CardAngleLetter :letter="letter" :color="color" />
        <CardAngleSymbol :symbol="symbol" />
    </div>
</template>
  
<script>
import CardAngleLetter from '../components/CardAngleLetter.vue'
import CardAngleSymbol from '../components/CardAngleSymbol.vue'

export default {
    name: 'CardAngle',
    components: { CardAngleLetter, CardAngleSymbol },
    props: {
        color: String,
        letter: String,
        symbol: String,
        top: String,
        bottom: String,
        left: String,
        right: String,
        scale: String,
    },
    computed: {
        style() {
            return {
                top: `${this.top}`,
                bottom: `${this.bottom}`,
                left: `${this.left}`,
                right: `${this.right}`,
                transform: `scaleY(${this.scale}1)`
            }
        }
    }
}
</script>
  
<style lang="scss" scoped>
.container {
    position: absolute;
}
</style>
