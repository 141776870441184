<template>
    <component :is="Symbol" class="content" />
</template>
  
<script>
export default {
    name: 'CardAngleSymbol',
    props: {
        symbol: String,
    },
    data() {
    return {
        Symbol: null,
    };
  },
  async created() {
        if (this.symbol) {
            this.Symbol = (await import(`../assets/icons/${this.symbol}Icon.vue`)).default;
        }
  },
}
</script>
  
<style lang="scss" scoped>
    .content {
        @media screen and (min-width: 601px) {
            width: 40px;
        }
        @media screen and (max-width: 600px) {
            width: 32px;
        }
    }
</style>
