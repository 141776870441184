<template>
    <p class="content" :style="style">{{ letter }}</p>
</template>
  
<script>
export default {
    name: 'CardAngleLetter',
    props: {
        letter: String,
        color: String
    },
    computed: {
        colorMap() {
            return {
                $primary: '#171717',
                $red: '#5e1213'
            };
        },
        style() {
            const colorValue = this.colorMap[this.color] || this.color;
            return {
                color: `${colorValue}`
            }
        }
    },
}
</script>
  
<style lang="scss" scoped>
    .content {
        // font-size: 36px;
        padding: 0 0 0 8px;
        @media screen and (min-width: 601px) {
            font-size: 36px;
        }
        @media screen and (max-width: 600px) {
            font-size: 24px;
        }
    }
</style>
