<template>
    <TitleLayer title="Contact" image="receptionLayer" />
    <section class="content">
        <div>
            <p class="textPadding">Vous avez des questions ? Des suggestions ? Des propositions de collaboration ? Contactez-nous, nous nous ferons un plaisir d'échanger avec vous.</p>
        </div>
        <div class="containerCenterMultiple">
            <ClickButtonSpec link="tel:+33678098411" value="Téléphone" />
            <ClickButtonSpec link="mailto:afrim.kaca@gmail.com" value="Email" />
        </div>
        <div>
            <p class="textPadding">
                Vous pouvez nous contacter par téléphone au <span class="highlight">06 78 09 84 11</span>
                <br>
                <br>
                ou bien par email à l'adresse <span class="highlight">afrim.kaca@gmail.com</span>.
            </p>
        </div>
    </section>
</template>

<style lang="scss" scoped>
</style>

<script>
import TitleLayer from '../components/TitleLayer.vue';
import ClickButtonSpec from '../components/ClickButtonSpec.vue';

export default {
    components: { TitleLayer, ClickButtonSpec }
}
</script>