<template>
    <section class="containerCenter layer" :style="style">
        <h1 class="z1">{{ title }}</h1>
    </section>
</template>

<style lang="scss" scoped>
.layer {
    position: relative;
    width: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media screen and (min-width: 601px) {
        height: calc(128px + (3 * 128px)); // Max height for menu + submenus
    }
    @media screen and (max-width: 600px) {
        height: 200px;
    }
}
</style>
  
<script>
export default {
    name: 'TitleLayer',
    props: {
        title: String,
        image: String
    },
    computed: {
        style() {
            return {
                backgroundImage: `url(${require(`../assets/images/titleLayers/${this.image}.png`)})`
            }
        }
    },
}
</script>

