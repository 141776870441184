<template>
    <TitleLayer title="Événements" image="eventsLayer" />
    <section class="container reverse">
        <div>
            <p>Magie sous les pins participe à de nombreux événements dans le but de promouvoir la magie. L'école possède généralement son propre stand lors des fêtes, ou organise parfois des événements comme des spectacles ou des démonstrations.</p>
        </div>
        <div>
            <EventCalendar date="18 Jui 2024" event="Spectacle de fin d'année" place="Roquefort" />
            <EventCalendar date="20 Oct 2024" event="Fête de la courge" place="Chateauneuf" />
            <EventCalendar date="31 Oct 2023" event="Halloween" place="Valbonne" />
            <EventCalendar date="21-23 Déc 2024" event="Marché de Noël" place="Valbonne" />
        </div>
    </section>
</template>

<style lang="scss" scoped>
.container {
    display: flex;
    @media screen and (min-width: 601px) {
        flex-direction: row;
        div:first-child {
            width: 60%;
        }
    }
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
    p {
        padding-left: 8vw;
        padding-right: 8vw;
    }
}
</style>

<script>
import TitleLayer from '../components/TitleLayer.vue';
import EventCalendar from '../components/EventCalendar.vue';
export default {
    components: { TitleLayer, EventCalendar }
}
</script>