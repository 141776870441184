<template>
    <main>
        <MainMenu />
        <RouterView />
        <MainFooter />
    </main>
</template>

<script>
import MainMenu from './components/MainMenu.vue'
import MainFooter from './components/MainFooter.vue'

// import './layouts/_main.scss'
// import './layouts/_utils.scss'
// import './layouts/_variables.scss'

export default {
    name: 'App',
    components: {
        MainMenu,
        MainFooter
    }
}
</script>

<style lang="scss">
// @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
// @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url(https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap);

html, body {
    // font-family: 'Roboto', sans-serif;
    // font-family: 'DM Sans', sans-serif;
    font-family: 'Jost', sans-serif;
}
</style>
