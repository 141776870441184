<template>
    <TitleLayer title="Tarifs" image="faceCardsLayer" />
    <section>
        <div>
            <p class="textPadding">L'école de magie <em>Magie sous les pins</em> étant une association, elle propose des tarifs très avantageux pour ses membres, pour une très haute expertise en magie. Une cotisation est obligatoire pour participer au fonctionnement de l'association. Le reste du tarif est très accessible comme vous pouvez le voir, alors n'attendez plus et profitez-en !</p>
        </div>
        <div class="containerCenter container">
            <CardFace title="Cotisation" text="20 euros" letter="A" color="$red" symbol="Heart" class="animation" />
            <CardFace title="Adhésion annuelle" text="2 cours / semaine : 280 euros" letter="A" color="$primary" symbol="Club" class="animation" />
            <CardFace title="Adhésion semestrielle" text="2 cours / semaine : 140 euros" letter="A" color="$red" symbol="Diamond" class="animation" />
        </div>
        <div>
            <p class="textPadding">Vous n'êtes pas sûr que la magie est faite pour vous ? Nous pensons au contraire qu'elle peut être fait pour n'importe qui prenant le temps de s'y intéresser. Toujours des doutes ? Pas de souci, nous offrons le premier cours pour que vous n'ayez aucun regret.</p>
        </div>
    </section>
</template>

<style lang="scss" scoped>
svg {
    height: 40px;
    width: 40px;
}
.container {
    flex-wrap: wrap;
    background: $primary;
}
.animation {
    &:hover {
        transform: scale(1.04);
    }
}
</style>

<script>
import TitleLayer from '../components/TitleLayer.vue';
import CardFace from '../components/CardFace.vue'

export default {
    components: { TitleLayer, CardFace }
}
</script>
