<template>
    <article class="container">
        <div class="image content">
            <img :alt="imageAlt" :src="imageSrc" class="image clearPadding" />
        </div>
        <div class="containerCenter content">
            <p>{{ text }}</p>
        </div>
    </article>
</template>
  
<script>
export default {
    name: 'ImageTextLayout',
    props: {
        imageUrl: String,
        imageAlt: String,
        text: String
    },
    computed: {
        imageSrc() {
            return require(`../assets/images/${this.imageUrl}.png`);
        }
    }
}
</script>
  
<style lang="scss" scoped>
.container {
    display: flex;
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}
.image {
    width: 100%;
}
@media screen and (min-width: 601px) {
    .content {
        position: relative;
        display: flex;
        flex: 1;
        &:last-child {
            padding: 0 64px;
        }
    }
    .image {
        height: 1024px;
        object-fit: cover;
        object-position: top;
    }
}
@media screen and (max-width: 600px) {
    .content {
        text-align: center;
    }
}
</style>

