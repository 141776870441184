<template>
    <article class="card transition">
        <CardAngle :letter="letter" :color="color" :symbol="symbol" top="16px" left="16px" />
        <p class="contentCenter"><strong>{{ title }}</strong></p>
        <p class="contentCenter">{{ text }}</p>
        <CardAngle :letter="letter" :color="color" :symbol="symbol" bottom="16px" right="16px" scale="-" />
    </article>
</template>
  
<script>
import CardAngle from '../components/CardAngle.vue'

export default {
    name: 'CardFace',
    components: { CardAngle },
    props: {
        title: String,
        text: String,
        letter: String,
        color: String,
        symbol: String
    }
}
</script>
  
<style lang="scss" scoped>
svg {
    height: 40px;
    width: 40px;
}
p {
    // padding: 20px;
    @media screen and (min-width: 601px) {
        padding: 20px;
    }
    @media screen and (max-width: 600px) {
        // padding: 8px;
        padding: 4px;
    }
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // width: 360px;
    // height: 500px;
    border-radius: 24px;
    color: $primary;
    background: $secondary;
    user-select: none;
    @media screen and (min-width: 601px) {
        width: 360px;
        height: 500px;
        margin: 64px 32px;
        padding-left: 64px;
        padding-right: 64px;
    }
    @media screen and (max-width: 600px) {
        width: 180px;
        height: 250px;
        margin: 32px 16px;
        padding-left: 32px;
        padding-right: 32px;
    }
}
</style>
