<template>
        <article class="sliderElement">
            <div class="imgInfo" :style="styleImg">
                <img :src="require(`../assets/images/magicians/${image}`)" />
            </div>
            <div class="textInfo" :style="styleTxt">
                <h3>{{ name }}</h3>
                <p>{{ info }}</p>
                <div class="innerOverlay"></div>
            </div>
        </article>
</template>
  
<script>
export default {
    name: 'MagicianRecord',
    props: {
        image: String,
        name: String,
        info: String,
        bg_color: String,
        bg_image: String,
    },
    data() {
        return {
            isDesktop: window.innerWidth >= 601
        };
    },
    computed: {
        styleImg() {
            return {
                background: `${this.bg_color}`
            }
        },
        styleTxt() {
            return this.isDesktop
                ? {
                    backgroundImage: `url(${require(`../assets/images/magicians/background/${this.bg_image}.png`)})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }
                : {
                    background: this.background
                };
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    // Only for performance purpose
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.isDesktop = window.innerWidth >= 601;
        }
    }
}
</script>
  
<style lang="scss" scoped>
.sliderElement {
    display: flex;
    @media screen and (min-width: 601px) {
        position: absolute;
        width: 100%;
        height: 600px;
    }
    @media screen and (max-width: 600px) {
        position: relative;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        scroll-snap-align: start;
    }
}

.imgInfo, .textInfo {
    @media screen and (min-width: 601px) {
        flex: 1;
    }
}

.textInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 601px) {
        justify-content: center;
        height: 100%;
        h3 {
            padding: 64px 0;
            font-size: 32px;
        }
        h3, p {
            padding-left: 4rem;
            z-index: 1;
        }
        .innerOverlay {
            position: absolute;
            width: 100%;
            height:100%;
            top:0;
            left:0;
            background: #000;
            opacity: .8;
            z-index: 0;
        }
    }
    @media screen and (max-width: 600px) {
        height: 320px;
        h3 {
            padding: 32px 0;
            font-size: 20px;
        }
        p {
            padding: 0 20px;
        }
    }
}

.imgInfo {
    @media screen and (min-width: 601px) {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 500px;
            width: 320px;
            padding: 0;
            border-radius: 16px;
            object-fit: cover;
        }
    }
    @media screen and (max-width: 600px) {
        text-align: center;
        height: 400px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            padding: 0;
        }
    }
}
</style>
