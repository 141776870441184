<template>
    <TitleLayer title="Cours" image="magicStuffLayer" />
    <section class="content">
        <p class="textPadding">
            Les cours de magie ont lieu à Valbonne village les soirs de la semaine et le mercredi après-midi. Certains événements viennent s'ajouter aux cours pour les plus motivés, comme les spectacles, les préparations aux spectacles, les rencontres avec de grands magiciens, les démonstrations.
            <br /><br />
            L'école accepte bien entendu tout âge et tout niveau. Des groupes sont fait pour permettre aux élèves d'évoluer au mieux et à leur rythme d'apprentissage. La magie est enseignée sous diverses de ses formes : cartes, accessoires (balles, élastiques, pièces, cordes, etc.), mentalisme, hypnose et spectacle sur scène.
            <br /><br />
            En plus d'une haute technicité, nous apportons à nos élèves toutes les clés pour leur permettre de réussir leur parcours de magicien, comme l'art oratoire, la distraction, l'humour, l'improvisation ou encore la présentation.
        </p>
        <p class="clearPaddingBottom textPadding">Retrouvez nos horaires de cours pour les :</p>
    </section>
    <section>
        <div class="containerCenterMultiple">
            <ClickButton link="" value="Enfants" @click="setText(1)" class="halfPadding" />
            <ClickButton link="" value="Ados" @click="setText(2)" class="halfPadding" />
            <ClickButton link="" value="Adultes" @click="setText(3)" class="halfPadding" />
        </div>
        <div class="containerCenterMultiple">
            <BoxNeon :value="currentText" :color="currentColor" />
        </div>
    </section>
</template>

<style lang="scss" scoped>
    @media screen and (max-width: 600px) {
        .halfPadding {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }
</style>

<script>
import TitleLayer from '../components/TitleLayer.vue';
import ClickButton from '../components/ClickButton.vue';
import BoxNeon from '../components/BoxNeon.vue';

export default {
    components: { TitleLayer, ClickButton, BoxNeon },
    data() {
        return {
            currentText: "Les cours ont lieu le mercredi après-midi, de 14h à 16h ou bien de 16h à 18h, pour les enfants de 8 à 12 ans",
            currentColor: "$secondary"
        };
    },
    methods: {
        setText(index) {
            const texts = [
                "Les cours ont lieu le mercredi après-midi, de 14h à 16h ou bien de 16h à 18h, pour les enfants de 8 à 12 ans",
                "Les cours ont lieu le vendredi soir, de 18h à 20h, pour les adolescants de 13 à 16 ans",
                "Les cours ont lieu le lundi soir de 18h à 20h pour les adultes débutants, et le mardi soir de 18h à 20h pour les adultes expérimentés"
            ];
            const colors = [
                "$secondary",
                "$blue",
                "$orange"
            ];
            this.currentText = texts[index - 1];
            this.currentColor = colors[index - 1];
        },
    },
}
</script>
