<template>
    <TitleLayer title="Stages" image="backCardsLayer" class="overlay" />
    <section class="content">
        <p class="textPadding">Les stages sont organisés pour permettre aux plus passionnés d'accélérer leur progression. Ils permettent un apprentissage intensif. Le travail est la clé pour être un bon magicien, encore faut-il travailler correctement. Le stage permet de travailler sa technique tout comme sa présentation sous les yeux de nos magiciens. Cela vous permet de recevoir leur retour en temps réel et de travailler spécifiquement vos défauts. Ainsi vous vous assurez de travailler de la bonne manière et de maîtriser des tours de magie rapidement.</p>
        <p class="textPadding">Laissez-moi vous faire un petit tour :</p>
    </section>
    <section>
        <p class="contentCenter">Pensez à un chiffre entre 1 et 3</p>
        <div class="foamBalls">
            <FlipComponent face="1" :back="answer_1" v-on:click="flip($event)" />
            <FlipComponent face="2" :back="answer_2" v-on:click="flip($event)" />
            <FlipComponent face="3" :back="answer_3" v-on:click="flip($event)" />
        </div>
    </section>
    <section class="">
        <p class="textPadding">Toujours pas convaincu ? <span id="span" class="invisible invisible-m transition">{{ reset }}</span></p>
    </section>
</template>

<script>
import TitleLayer from '../components/TitleLayer.vue';
import FlipComponent from '../components/FoamBallFlip.vue';

export default {
    components: { TitleLayer, FlipComponent },
    data() {
        return {
            answer_1: '',
            answer_2: '',
            answer_3: '',
            allowTrick: true,
            reset: ''
        };
    },
    methods: {
        flip(event) {
            if (!this.allowTrick)
                return
            event.currentTarget.querySelector('.box-inner').classList.add('chosenOne')
            
            const balls = document.getElementsByClassName('box-inner')
            balls[0].classList.add('selected')
            for (let i = 0; i < balls.length; i++) {
                balls[i].classList.add('selected')
                if (balls[i].classList.contains('chosenOne')) {
                    switch (i) {
                        case 0:
                            this.answer_1 = 'Pourquoi le 1 ?'
                            this.reset = 'Et si vous aviez pensé au 2 ou au 3 ?'
                            break
                        case 1:
                            this.answer_2 = 'Pourquoi le 2 ?'
                            this.reset = 'Et si vous aviez pensé au 1 ou au 3 ?'
                            break
                        case 2:
                            this.answer_3 = 'Pourquoi le 3 ?'
                            this.reset = 'Et si vous aviez pensé au 1 ou au 2 ?'
                            break
                    }
                }
            }
            this.allowTrick = false

            setTimeout(() => {
                document.getElementById('span').classList.remove('invisible', 'invisible-m')
            }, "2000");
            return
        },
    },
}
</script>

<style lang="scss" scoped>
.overlay {
    &:after {
        background:rgba(55,83,140,.2);
        filter: saturate(2);
    }
}
.content {
    background: $darkBlue;;
}
.foamBalls {
    display: flex;
    @media screen and (min-width: 601px) {
        justify-content: space-around;
        margin-top: 80px;
        margin-bottom: 80px;
    }
    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 40px;
        p {
            padding: 20px;
        }
    }
}
</style>
