<template>
    <div class="boxContainer transition" :style="style">
        <p>{{ value }}</p>
    </div>
</template>
  
<script>
export default {
    name: 'BoxNeon',
    props: {
        color: String,
        value: String
    },
    computed: {
        colorMap() {
            return {
                $secondary: '#f9f3ec',
                $blue: '#37538c',
                $orange: '#bf612a'
            };
        },
        style() {
            const colorValue = this.colorMap[this.color] || this.color;
            return {
                boxShadow: `0 0 64px 0 ${colorValue}`
            }
        }
    },
}
</script>
  
<style lang="scss" scoped>
.boxContainer {
    display: flex;
    align-items: center;
    border-radius: 24px;
    @media screen and (min-width: 601px) {
        width: 50vw;
        height: 300px;
        margin: calc(64px + 64px) auto;
        padding: 0 4vw;
    }
    @media screen and (max-width: 600px) {
        width: 60vw;
        height: 200px;
        margin: calc(32px + 64px) auto;
        padding: 0 4vw;
    }
    p {
        padding: 0;
    }
}
</style>
