<template>
<div class="box">
    <div class="box-inner">
        <div class="box-front foam-ball-dark">
            <p>{{ face }}</p>
        </div>
        <div class="box-back foam-ball-dark">
            <p>{{ back }}</p>
        </div>
    </div>
</div>
</template>
  
<script>
export default {
    name: 'FlipComponent',
    props: {
        face: String,
        back: String
    },
}
</script>
  
<style lang="scss" scoped>
.box {
    background-color: transparent;
    perspective: 1000px;
    width: 150px;
    height: 150px;
    cursor: pointer;
    user-select: none;
    @media screen and (max-width: 600px) {
        margin: 16px 0;
    }
}

.box-inner {
    transform-style: preserve-3d;
    transition: transform 1.6s ease-in-out;
    position: relative;
    width: 100%;
    height: 100%;
}

.box-front, .box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.box-front {
    background: #cccccc;
    color: #111111;
}

.box-back {
    background: #8ebf42;
    color: #eeeeee;
    transform: rotateY(180deg);
}

// White mode
.foam-ball-white {
    width: 150px;
    height: 150px;
    background: radial-gradient(circle at 30% 30%, #ff4d4d, #e60000 60%, #990000);
    border-radius: 50%;
    box-shadow: 
        0px 10px 20px rgba(0, 0, 0, 0.5), /* Main shadow for depth */
        inset 5px -5px 10px rgba(255, 255, 255, 0.5), /* Inner highlight */
        inset -10px 5px 15px rgba(0, 0, 0, 0.3); /* Inner shadow for shading */
    display: flex;
    align-items: center;
    justify-content: center;
}

// Dark mode
.foam-ball-dark {
    width: 150px;
    height: 150px;
    background: radial-gradient(circle at 30% 30%, #ff5e5e, #ff1a1a 60%, #990000);
    border-radius: 50%;
    box-shadow: 
        0px 12px 25px rgba(255, 255, 255, 0.15), /* Softer but visible shadow */
        inset 5px -5px 12px rgba(255, 255, 255, 0.6), /* Stronger inner highlight */
        inset -10px 5px 20px rgba(0, 0, 0, 0.5); /* Enhanced shading */
    display: flex;
    align-items: center;
    justify-content: center;
}

.foam-ball-dark p, .foam-ball-white p {
    font-weight: bold;
    color: #fff;
    text-align: center;
    position: absolute;
    padding: 0 12px;
}

.box-inner div:first-child p, .box-inner div:first-child p {
    font-size: 48px;
    /* Round effect 3D */
    transform: scale(1.1) skew(2deg, -2deg);
    text-shadow: 
        -1px -1px 2px rgba(0, 0, 0, 0.4),
        2px 2px 4px rgba(255, 255, 255, 0.4);
}
.box-inner div:last-child p, .box-inner div:last-child p {
    font-size: 24px;
}

.selected {
    transform: rotateY(180deg) !important;
}

</style>
